import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import compositeActions from "../../data/actions/compositeActions";
import actions from "../../data/actions/actions";
import ConfirmBox from "../confirmBox/confirmDialog";
import updateForm, { loadFormData } from "../../data/actions/formStateController";
import saveFormData from "../../data/actions/formActions";
import showHeaderNotification from "../../utils/showNotification"
import WarningDialog from "../../components/warningDialog";
import { ReactComponent as GapsFull } from "../../assets/img/gapsFull.svg"
import { ReactComponent as GapsHalf } from "../../assets/img/gapsHalf.svg"
import checkRoleAccess from '../../utils/checkRoleAccess';


const getUrlParameterByName = (paramname, url) => {
  if (!url) url = window.location.href;
  paramname = paramname.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + paramname + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}


class BlindsControl extends Component {
  constructor(props) {
    super(props);
    this.debounceTimeout = null;
    this.relayOnTime = 0;
    this.relayStartTime = null;
    this.relayEndTime = null;
    this.state = {
      sliderValue: null,
      isDialogShown: false,
      calibrateShown: false,
      blindsExecuting: false,
      showCalibrateButtons: false,
      applyAnimation : false,
      isWarningShown : false,
      message:""
    }
  }


  debounce(fn) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      fn();
    }, 500)
  }

/*
   ///OVO JE OPASNO JOPA
  componentDidUpdate(nextProps) {
    this.setState({
      sliderValue: null
    });
  }
  */

  changeStateOnSliderMove(e) {
    let inputValue = parseInt(e.target.value, 10);

    this.setState({
      sliderValue: inputValue,
      applyAnimation : true
    });
    if (_.includes((this.props.route.location.pathname).toLowerCase(), "scene")) {
      let deviceId = parseInt(getUrlParameterByName("deviceId"), 10)
      var obj = { "compositeDeviceId": parseInt(getUrlParameterByName("deviceId"), 10), "value": 255 - ((255 / parseInt(4, 10)) * parseInt(inputValue, 10)) }
      var selection = this.props.sceneParameter === "active" ? _.clone(this.props.sceneForm.params.sceneActiveDeviceState) :
        _.clone(this.props.sceneForm.params.sceneExitDeviceState);

      var myObj = _.find(selection, obj => { return obj.compositeDeviceId === deviceId })
      selection[_.indexOf(selection, myObj)] = obj;
      var params = {};
      var ob = { params };
      if (this.props.sceneParameter === "active") {
        ob.params["sceneActiveDeviceState"] = selection;
        ob.params["sceneExitDeviceState"] = this.props.sceneForm.params.sceneExitDeviceState;
        ob.params["groups"] = this.props.sceneForm.params.groups;
        updateForm("scene", ob);
      }
      else {
        ob.params["sceneActiveDeviceState"] = this.props.sceneForm.params.sceneActiveDeviceState;
        ob.params["sceneExitDeviceState"] = selection;
        ob.params["groups"] = this.props.sceneForm.params.groups;
        updateForm("scene", ob);
      }
    }
  }

  setMinOrMaxAnalogOutputValue(minOrMax, compositeDevice) {
    if(_.isUndefined(compositeDevice.lockState) || compositeDevice.lockState === false){
      let id = null;

    switch (minOrMax) {
      case "min":
        id = parseInt(getUrlParameterByName("deviceId"), 10);
        compositeActions.setAnalogOutputState(id, 0);
        break;
      case "max":
        id = parseInt(getUrlParameterByName("deviceId"), 10);
        compositeActions.setAnalogOutputState(id, 255);
        break;

      default:
        break;
    }
  }
    else {
      this.setState({
        isWarningShown:true,
        message:"Device is locked. Cannot change state at this moment"
      })
    }
  }


  forceStopShuttersFromOpeningOrClosing(compositeDevice) {
      let id = null;
      id = parseInt(getUrlParameterByName("deviceId"), 10);
      compositeActions.setAnalogOutputState(id, 1000);
  }


  setGAPAnalogOutputValue(gap1OrGap2, compositeDevice) {
    if(_.isUndefined(compositeDevice.lockState) || compositeDevice.lockState === false){
      let id = null;

    switch (gap1OrGap2) {
      case "gap1":
        id = parseInt(getUrlParameterByName("deviceId"), 10);
        compositeActions.setAnalogOutputState(id, 300);
        break;
      case "gap2":
        id = parseInt(getUrlParameterByName("deviceId"), 10);
        compositeActions.setAnalogOutputState(id, 400);
        break;

      default:
        break;
    }
  }
    else {
      this.setState({
        isWarningShown:true,
        message:"Device is locked. Cannot change state at this moment"
      })
    }
  }
  hideDialog() {
    this.setState({
      isDialogShown: false
    })
  }

  showConfirmDialog() {
    this.setState({
      isDialogShown: true
    })
  }
  showCalibrateOption() {
    this.setState({
      calibrateShown: this.state.calibrateShown === true ? false : true
    })
  }
  showCalibrateButtons() {
    this.setState({
      showCalibrateButtons: this.state.showCalibrateButtons === true ? false : true
    })

  }
  toggleRelay(device, direction) {
    actions.toggleSwitch(parseInt(device.id, 10), parseInt(device.value, 10))
    if(!device.value){
      this.relayStartTime = Date.now();
      this.relayOnTime = 0;
    }else{
      this.relayEndTime = Date.now();
      let timeInMillis = this.relayEndTime - this.relayStartTime;
      this.relayOnTime = (timeInMillis / 1000).toFixed(1);
    }
  }

  autocalibrate(compositeDeviceId){
    compositeActions.autocalibrateShutters(compositeDeviceId, this.getStateValue());
  }

  lockState(deviceId,lockState, e) {
    let currentStateValue = _.isUndefined(lockState) ? true : !lockState

    loadFormData("compositedevice", parseInt(deviceId, 10));

    var obj = {};
    obj["lockState"] = currentStateValue;
    updateForm("compositedevice", obj);
        
    saveFormData("compositedevice").then(res => {
      showHeaderNotification(true, 3000, "Device state "+(currentStateValue === true ? "" : "un")+"locked")
    })
  }
  getStateValue() {
    var deviceFromDb = _.find(this.props.compositeDevices, { "id": parseInt(getUrlParameterByName("deviceId"), 10) })
    let shutVal = deviceFromDb.value
    const valueToSend = this.state.sliderValue ? ((255 / 4) * parseInt((4- this.state.sliderValue), 10)) : (this.state.sliderValue === 0 ? 255 : shutVal)
    return parseInt(valueToSend, 10)
  }
  setAnalogValueViaButton(compositeDevice){
    if(_.isUndefined(compositeDevice.lockState) || compositeDevice.lockState === false){
      if(this.state.sliderValue !== null){
      this.setState({
        blindsExecuting: true,
        applyAnimation:false
      })
     // let stateVal = this.state.sliderValue === null ? 0 : this.state.sliderValue
  
      let id = parseInt(getUrlParameterByName("deviceId"), 10);
      let valueToSend = this.getStateValue();//255 - ((255 / parseInt(4, 10)) * parseInt(stateVal, 10));
  
      this.debounce(() => {
        // setBlindsExecuting(id);
        compositeActions.setAnalogOutputState(id, parseInt(Math.round(valueToSend), 10)).then(res => {
          this.setState({ blindsExecuting: false })
        }).catch(err => {
          this.setState({ blindsExecuting: false })
        });
      })
    }
    }
    else {
      this.setState({
        isWarningShown:true,
        message:"Device is locked. Cannot change state at this moment"
      })
    }
    

}

hideWarning(){
  this.setState({
    isWarningShown:false,
    message:"",    
  })
}
  render() {

    var deviceFromDb = _.find(this.props.compositeDevices, { "id": parseInt(getUrlParameterByName("deviceId"), 10) })
    
    if (_.isUndefined(deviceFromDb)) {
      return null
    }
    let upRelay = _.find(this.props.devices, { id: parseInt(deviceFromDb.params.upRelay.value, 10) })
    let downRelay = _.find(this.props.devices, { id: parseInt(deviceFromDb.params.downRelay.value, 10) })

    if(_.isUndefined(downRelay) || _.isUndefined(upRelay)){
      return <div className="analog-output-control blinds"> 

      <div className="analog-output-middle blinds animated faster zoomIn">
        <div className="device-modal-dialog-name text-center">
        <i onClick={this.props.onHideDialog} className="fa fa-times pull-right close slider-close pointer" aria-hidden="true"></i>

        <div className="text-center">
        Shutter relays are undefined.
        </div>

        </div>
      </div>

       </div>;
    }
    let shutVal = deviceFromDb.value

    if(deviceFromDb.value === 300 || deviceFromDb.value === 400){
      shutVal = 0
    }

    let sliderValue = this.state.sliderValue === null ? 4 - ((shutVal * 4) / 255) : this.state.sliderValue;

    if (_.includes((this.props.route.location.pathname).toLowerCase(), "scene")) {
      var arrayToSearch = [];
      if (this.props.sceneParameter === "active") {
        arrayToSearch = this.props.sceneForm.params.sceneActiveDeviceState;
      }
      else {
        arrayToSearch = this.props.sceneForm.params.sceneExitDeviceState;
      }
      sliderValue = 4 - (((_.find(arrayToSearch, { "compositeDeviceId": parseInt(getUrlParameterByName("deviceId"), 10) })).value * 4) / 255)

    }

    let animationDiv = <div></div>
    if (!_.includes((this.props.route.location.pathname).toLowerCase(), "scene")) {
      if (deviceFromDb.isExecuting) {
        animationDiv = <div className="animation-wrapper">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </div>
      }
    }
  
  // console.log(this.props.this.props.userProfile);
let isGapControlEnabled = false
if(deviceFromDb.params.gapsControlEnabled && deviceFromDb.params.gapsControlEnabled.value === true){
  isGapControlEnabled = true
}
let forceStopExectingButtonEnabled = false
if(deviceFromDb.params.forceStopExectingButtonEnabled && deviceFromDb.params.forceStopExectingButtonEnabled.value === true){
  forceStopExectingButtonEnabled = true
}

let isFullScreen = false;
  if( window.innerHeight === window.screen.height) {
      // browser is fullscreen
      isFullScreen = true;
  }
    let settingsButtonStyles = this.state.calibrateShown === true ? "btn btn-default calibrate-option animated faster zoomIn" : "hidden";
    return (
      <div className={isFullScreen?"analog-output-control blinds top70" : "analog-output-control blinds"}>
        <ConfirmBox
          blindsControl={true}
          hideDialog={this.hideDialog.bind(this)} noAction={this.hideDialog.bind(this)}
          isDialogShown={this.state.isDialogShown} yesAction={() => { }}
          message="Poruka" />

          <WarningDialog message={this.state.message} hideWarning={this.hideWarning.bind(this)} isShown={this.state.isWarningShown}/>

        <div className={"analog-output-middle blinds animated faster zoomIn " + (isGapControlEnabled ? "hasGapsButtons" : "") }>
        <div  className="device-modal-dialog-name text-center">{deviceFromDb.name}</div>

          <div className="range-slider text-center blinds">
            <i onClick={this.showCalibrateOption.bind(this)}  className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "lock-icon-style pointer fa fa-cogs"}></i>
            
            <button onClick={this.showCalibrateButtons.bind(this)} className={(this.props.userProfile.level>0 || checkRoleAccess("admin") )?  settingsButtonStyles + " m-left-190 analog-device-lock-option" : "hidden"} >MANUAL CALIBRATION</button>
            <button onClick={this.lockState.bind(this, deviceFromDb.id, deviceFromDb.lockState )} className={settingsButtonStyles +" analog-device-lock-option m-top-50"} >{deviceFromDb.lockState === true ? "UNLOCK STATE" : "LOCK STATE"}</button>

            <button onClick={this.autocalibrate.bind(this, deviceFromDb.id)} className={settingsButtonStyles + " analog-device-lock-option"} >CALIBRATE AND SET</button>

            <i onClick={this.props.onHideDialog} className="fa fa-times pull-right close slider-close pointer" aria-hidden="true"></i>
            <div  className="vertical-aligned blinds-slider-wrapper">
              <input className="blinds-input"
                type="range" min="0" max="4"
                step="1"
                // value={sliderValue}
                value={sliderValue}

                onChange={this.changeStateOnSliderMove.bind(this)} />
   <input orient="vertical" className="analog"
                type="range" min="0" max="4"
                step="1"
                value={sliderValue}
                onChange={this.changeStateOnSliderMove.bind(this)}
              />
            </div>

              {/* <div className="vertical-aligned">
           
            
            </div> */}
            {animationDiv}
          </div>

          <div className="on-off-buttons-slider relative-position text-center">
            <div className="on-off-buttons-slider-inside text-center">
              <div className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : ""}>
                <div className={this.state.showCalibrateButtons === false ? "" : "hidden"}>
                  <button disabled={deviceFromDb.isExecuting || this.state.blindsExecuting === true ? true : false} 
                  onClick={this.setMinOrMaxAnalogOutputValue.bind(this, "min", deviceFromDb)} 
                  className={deviceFromDb.value === 0 ? "btn btn-default off-active pointer shutters-max-btns" : "btn btn-default shutters-max-btns pointer"}>MAX DOWN</button>
                  <button 
                  disabled={deviceFromDb.isExecuting || this.state.blindsExecuting === true ? true : false}
                   onClick={this.setAnalogValueViaButton.bind(this, deviceFromDb)} 
                  className={this.state.applyAnimation === false ? "btn btn-default shutters-max-btns pointer" : "btn btn-default shutters-max-btns pointer animated faster pulse"  }>
                    APPLY
                  </button>
                  <button disabled={deviceFromDb.isExecuting || this.state.blindsExecuting === true ? true : false}  
                  onClick={this.setMinOrMaxAnalogOutputValue.bind(this, "max", deviceFromDb)} 
                  className={deviceFromDb.value === 255 ? "btn btn-default shutters-max-btns on-active pointer" : "btn btn-default shutters-max-btns pointer"}>MAX UP</button>
                
                { 
                isGapControlEnabled ? 
                <div><button style={{padding:"6px 12px", margin:"0 10px"}}
                  disabled={deviceFromDb.isExecuting || this.state.blindsExecuting === true ? true : false}
                  onClick={this.setGAPAnalogOutputValue.bind(this, "gap1", deviceFromDb)} 
                  className={this.state.applyAnimation === false ? "btn btn-default shutters-max-btns pointer" : "btn btn-default shutters-max-btns pointer animated faster pulse"  }>
                    <GapsHalf style={{width:"32px", height:"32px"}}></GapsHalf>
                    
                    {/* <img src={GapsHalf} alt="React Logo" /> */}
                  </button>

                  {forceStopExectingButtonEnabled ? <button className="btn btn-default shutters-max-btns pointer" style={{padding:"6px 12px", margin:"0 10px"}} disabled={deviceFromDb.isExecuting || this.state.blindsExecuting === true ? false : true} onClick={this.forceStopShuttersFromOpeningOrClosing.bind(this, deviceFromDb)}>
                  <i style={{fontSize:"38px"}} className='icon-stop1 white text-white' ></i>
                  </button> : null}

                  <button style={{padding:"6px 12px", margin:"0 10px"}}
                  disabled={deviceFromDb.isExecuting || this.state.blindsExecuting === true ? true : false}
                  onClick={this.setGAPAnalogOutputValue.bind(this, "gap2", deviceFromDb)} 
                  className={this.state.applyAnimation === false ? "btn btn-default shutters-max-btns pointer" : "btn btn-default shutters-max-btns pointer animated faster pulse"  }>
                    
                    {/* <img src={GapsFull} alt="React Logo" /> */}
                    <GapsFull style={{width:"32px", height:"32px"}}></GapsFull>

                  </button></div> : null}
                
                
                
                </div>
                <div className={this.state.showCalibrateButtons === true ? "" : "hidden"}>
                <div>
                    {/* When using calibrate buttons you must sync your shutters state manually. */}
                    </div>
                  <div>
                   
                    <label className="calibrate-toggle-label" htmlFor="">Toggle up: </label>
                    <input onChange={this.toggleRelay.bind(this, upRelay)} disabled={downRelay.value === 1 ? true : false} type="checkbox" checked={upRelay.value} className="toggle m-left-10" />
                  </div>
                  <div>
                    <label className="calibrate-toggle-label" htmlFor="">Toggle down:</label>
                    <input onChange={this.toggleRelay.bind(this, downRelay)}  disabled={upRelay.value === 1 ? true : false} type="checkbox" checked={downRelay.value} className="toggle m-left-10" />
                  </div>
                  <div>
                    <label className="calibrate-toggle-label pt-2" htmlFor="">Relay on time: {this.relayOnTime} s</label>
                  </div>
                </div>
              </div>
            </div>
            <div className={!_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : ""}>

              <div className="slider-button pointer color-white margin-top-0" onClick={this.props.onHideDialog}><strong>Save</strong> </div>
            </div>
          </div>

          {/* {confimButton} */}
          <div className="text-center">
            {/* <div style={calibrateButtonStyle} onClick={this.showConfirmDialog.bind(this)} className="text-center btn btn-md"><i className="fa fa-arrows-alt"></i> calibrate</div> */}

          </div>

        </div>
      </div>

    )
  }
}

export default branch({
  // compositeDevices: ["model", "compositedevices"],
  compositeDevices: ['monkeys', 'accessibleCompositeDevices'],
  devices: ["model", "devices"],
  sceneForm: ['forms', 'scene'],
  route: ["route"],
  ui: ["ui", "showMainMenu"],
  userProfile: ["sessionInfo","userProfile"]
}, BlindsControl);